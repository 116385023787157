.container{
  padding:50px;

  .title-section{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form{
    background-color: white;
    padding:40px;
    max-width: 600px;
    border-radius: 10px;
    margin: 0 auto;
  }
}
