.pov-button-shadow {
  // box-shadow: 0 4.3px 15px 0 rgba(65, 101, 237, 0.4) !important;
  &:hover {
    box-shadow: none !important;
  }
  font-weight: 500;
  font-size: 14px;
  min-width: 88px;
  height: 44px;
  box-shadow: rgba(65, 101, 237, 0.4) 0px 4.3px 15px 0px !important;
  padding: 0px 20px;
  border-radius: 4px;
}
