.please-scan-container{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.no-access-container{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
