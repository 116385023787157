.survey-container{
  padding-top:25px;

  .banner-wrapper{
    height: 230px;
    width:100%;
    overflow: hidden;
    border-radius: 20px !important;
    background-color: white;

    img{
      object-fit: cover;
      width:100%;
      height:100%;
    }
  }

  .section{
    margin:25px 0;
  }

  .ant-card-bordered{
     border-radius: 20px;
  }

  .survey-details{
    h2{
      margin-bottom:20px;
    }
  }

  .question-container{

    h3{
      font-weight: 400;
      font-size: 18px;
    }

    .input-component{
      margin-top:10px;
    }
  }

  .range-icon-wrapper{
    display: flex;

    .ant-slider{
      flex:1
    }

    span{
      padding:0 20px
    }
  }

}


.spinner {
  text-align: center;
  background: white;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 80px 50px;
  margin: 20px 0;
}

.pov-icon-survey{
  height:35px;
  // width:60px;
}
