.targeting_tab {
  :global(.ant-tabs-nav-scroll) {
    font-size: 14px;
    letter-spacing: 0.6px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    :global(.ant-tabs-nav .ant-tabs-tab) {
      padding: 18px 16px 12px;
    }
  }
}
.action_button {
  :global(.ant-btn) {
    border: 2px solid #c3c3c3;
    height: 38px;
    &:hover {
      border: 2px solid #a3cbfd;
    }
  }
}
