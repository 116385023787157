.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.title {
  color: #202124;
  margin-top: -10px;
}
.info {
  color: #70757a;
  font-size: smaller;
  margin-top: 3px;
  line-height: normal;
}
.modal {
  :global(.ant-modal-close-x) {
    margin-right: -20px;
    margin-top: -15px;
  }
  :global(.ant-modal-footer) {
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
