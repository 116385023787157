@import 'assets/styles/mixins.scss';

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
  font-size: large;
  font-weight: bold;
}
.ant-tabs-bar {
  border-bottom: none;
}
.ant-tabs {
  margin-top: -80px;
}
.ant-tabs-ink-bar {
  height: 4px;
}
.ant-tabs-small-bar {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid rgba(0, 0, 0, 0.075);
  .ant-tabs-nav .ant-tabs-tab {
    padding: 18px 16px 12px;
  }
  .ant-tabs-nav-wrap {
    background: white;
    // margin-top: 30px;
  }
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: flex-start;
    // font-size: 14px;
    letter-spacing: 0.6px;
    // font-size:16px;

    font-weight: bold;
  }
  .ant-tabs-extra-content {
    background: white;
    line-height: 56px !important;
  }
}
