@import 'assets/styles/mixins.scss';

.login {
  background-size: cover;
  background-color: $gray-lighten-more;
  background-position: center center;
}

.title {
  margin: 0 auto;
  max-width: rem(740);
  text-align: center;
  padding-left: rem(20);
  padding-right: rem(20);
  h1 {
    font-weight: 900;
    margin-bottom: rem(30);
  }
}

.block {
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: $md-max-width) {
    padding: rem(20);
  }

  @media (max-width: 767px) {
    width: auto;
    position: relative;
    left: unset;
    top: unset;
    transform: none;
    margin-top: 30px;
  }
}

.inner {
  min-width: rem(300);
  max-width: rem(500);
  margin: 0 auto;
  padding: rem(50) rem(40) rem(20);
  border-radius: 10px;
  overflow: hidden;
  background-color: $white;
  position: relative;

  @media (max-width: $md-max-width) {
    padding: rem(30) rem(30) rem(10);
  }
}

.form {
  position: relative;
  z-index: 2;
}
.btnFacebook {
  width: 165px;
  height: 35px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0 transparent;
  text-align: center;
  margin: 5px;
  display: inline-block;
}

@media (max-width: 767px) {
  .ant-form {
    .form-actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-btn {
        margin-bottom: 10px;
      }
    }

    & > .form-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}
