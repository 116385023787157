.authentication-form-wrapper {
  @media (max-width: 767px) {
    width: auto;
    position: relative;
    left: unset;
    top: unset;
    transform: none;
    margin-top: 0px;

    .ant-form {
      .form-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-btn {
          margin-bottom: 10px;
        }
      }

      & > .form-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.survey-container {
  @media (max-width: 767px) {
    .ant-col-xs-offset-2 {
      margin-left: 0;
    }
    .ant-col-xs-20 {
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      margin: 0 auto;
      float: none;
      padding-inline: 15px;
    }

    .ant-form {
      & > .ant-row.ant-form-item {
        .ant-form-item-control-wrapper {
          text-align: center;

          .ant-btn {
            width: 200px;
            height: 40px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }
        }
      }

      .question-container {
        .ant-card {
          .ant-form-item {
            margin-bottom: 0;
          }
        }

        .ant-radio-group {
          .ant-radio-wrapper {
            width: 45%;
            margin-bottom: 10px;
          }
        }

        .ant-checkbox-wrapper {
          width: 47%;
          margin-bottom: 10px;
        }

        .ant-calendar-picker,
        .ant-input-number {
          width: 100%;
        }

        span.ant-radio + *,
        .ant-checkbox-group-item span,
        .range-icon-wrapper span {
          font-weight: 500;
        }
      }
    }
  }
}

.ant-page-header-heading-extra {
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn.ant-btn-link {
      font-weight: bold;
      font-size: 14px;
      border: 1px solid #1479fa;
    }
  }
}

.authentication-layout {
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
}

.container-basic-detail {
  @media (max-width: 767px) {
    padding: 50px 15px;

    .form--basic-detail {
      padding-inline: 10px;

      .form-actions {
        margin-top: 20px;
        div {
          text-align: center;
          width: 100%;

          .ant-btn {
            width: 200px;
            height: 40px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
