@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  letter-spacing: 0.005em;
  font-weight: 400;
  min-height: 78px;
  height: 78px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  color: $text;
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 0;
  z-index: 111;
}
.pov_divider {
  border-left-style: solid;
  border-left-width: 1px;
  color: #dadce0;
  content: '';
  height: 32px;
  margin-left: 8px;
  margin-right: 8px;
  width: 1px;
}
.ls9 {
  letter-spacing: 0.9px;
}
